import React, {useState} from 'react';
import moment from "moment";
import WarningCreate from './WarningCreate';
import WarningDetail from './WarningDetail';
import {Button} from "../Button";
import Alert from "../Alert/Alert";
import {Can} from "../../../ability-context";
import Modal from "../Modal";
import {PageLoader} from "../Loader";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    createUserWarning,
    deleteUserWarningAccepted,
    fetchUserWarnings,
    updateUserWarningAccepted, USER_BEST_PHOTOS_KEY, USER_BEST_VIDEOS_KEY, USER_PROFILE_MEDIAS_KEY,
    USER_WARNINGS_KEY
} from "../../../api/public-voissa/users";
import {toastError, toastSuccess} from "../../../libs/toast";
import {WARNING} from "../../../const/toasts";
import {updateWarningContent, updateWarningStatus, WARNING_KEY} from "../../../api/public-voissa/warnings";
import Drawer from "../../components2/Drawer/Drawer";

const Warning = ({
        userId = null,
        username = "",
    }) =>{
    const queryClient = useQueryClient();

    const [currentWarningId, setCurrentWarningId] = useState(null);
    const [toggleDetails, setToggleDetails] = useState(false);
    const [toggleCreateWarning, setToggleCreateWarning] = useState(false);
    const [toggleAlertDeleteWarn, setToggleAlertDeleteWarn] = useState(false);

    let query = {limit: 50, sort: "-createdAt"}

    const {isSuccess : isSuccessWarnings, data: dataWarnings} = useQuery(
        [USER_WARNINGS_KEY, userId, query],
        () => fetchUserWarnings(userId, query),
        {
            staleTime: (1000 * 60 * 2),
            enabled: !!userId,
            placeholderData: {
                data: [],
                total: 0,
                more: false
            }
        }
    );

    const {
        mutate : mutateCreateWarning,
    } = useMutation(data => createUserWarning(userId, data),{
        onSuccess: async (data) =>{
            await queryClient.invalidateQueries([USER_WARNINGS_KEY, userId]);
            if(data.restriction.type === 3){
                await queryClient.invalidateQueries([USER_PROFILE_MEDIAS_KEY, userId]);
                await queryClient.invalidateQueries([USER_BEST_VIDEOS_KEY, userId]);
                await queryClient.invalidateQueries([USER_BEST_PHOTOS_KEY, userId]);
            }
        },
        onError: () =>{
            toastError(WARNING.ERROR.CREATE);
        }
    });

    const {
        mutate : mutateDeleteWarning,
    } = useMutation(warningId => deleteUserWarningAccepted(userId, warningId),{
        onSuccess: async (data) =>{
            await queryClient.invalidateQueries([USER_WARNINGS_KEY, userId]);
            await queryClient.invalidateQueries([USER_PROFILE_MEDIAS_KEY, userId]);
            if(data.restriction.type === 3){
                await queryClient.invalidateQueries([USER_BEST_VIDEOS_KEY, userId]);
                await queryClient.invalidateQueries([USER_BEST_PHOTOS_KEY, userId]);
            }
        },
        onError: () =>{
            toastError(WARNING.ERROR.DELETE);
        }
    });

    const {
        mutate : mutateAcceptedWarning,
    } = useMutation(warningId => updateUserWarningAccepted(userId, warningId),{
        onSuccess: async (data) =>{
            await queryClient.invalidateQueries([USER_WARNINGS_KEY, userId]);
            await queryClient.invalidateQueries([WARNING_KEY, data._id]);
            toastSuccess(WARNING.SUCCESS.UPDATE);
        },
        onError: () =>{
            toastError(WARNING.ERROR.UPDATE);
        }
    });

    const {
        mutate : mutateUpdatedStatusWarning,
    } = useMutation(data => updateWarningStatus(data.warningId, data.data),{
        onSuccess: async (data) =>{
            await queryClient.invalidateQueries([USER_WARNINGS_KEY, userId]);
            await queryClient.invalidateQueries([WARNING_KEY, data._id]);
            await queryClient.invalidateQueries([USER_PROFILE_MEDIAS_KEY, userId]);
            await queryClient.invalidateQueries([USER_BEST_VIDEOS_KEY, userId]);
            await queryClient.invalidateQueries([USER_BEST_PHOTOS_KEY, userId]);
        },
        onError: () =>{
            toastError(WARNING.ERROR.UPDATE);
        }
    });

    const {
        mutate : mutateUpdatedContentWarning,
    } = useMutation(data => updateWarningContent(data.warningId, data.data),{
        onSuccess: async (data) =>{
            await queryClient.invalidateQueries([USER_WARNINGS_KEY, userId]);
            await queryClient.invalidateQueries([WARNING_KEY, data._id]);
            toastSuccess(WARNING.SUCCESS.UPDATE);
        },
        onError: () =>{
            toastError(WARNING.ERROR.UPDATE);
        }
    });

    let {data = [], total = 0} = dataWarnings || {};

    function handleDeleteWarn(warningId) {
        mutateDeleteWarning(warningId);
        setCurrentWarningId(null);
        setToggleDetails(false);
        setToggleAlertDeleteWarn(false);
    }


    return !isSuccessWarnings ? (
        <div id="warning" className="modal-container">
            <PageLoader />
        </div>
    ) : (
        <div id="warning" className="modal-container">
            <div className={"modal-topline"}>
                <h3 className="row head-title full mb-8"><span
                    className="mr-8 red">{total}</span> Message{total >1 ? "s" : ""} de modération pour {username}</h3>
                <span className="row text small medium-grey bold full">Les messages ci-dessous ne sont visibles que par vous et l'équipe de modération.</span>
            </div>
            <div className={"modal-body"}>
                <div className="warning-title">Liste des messages de modération
                    {/*TODO: right*/}
                    <Can action={"create"} subject="Warning" >
                        <Button size={"small"}
                                btnStyle={"cancel-reverse"}
                                onClick={() => setToggleCreateWarning(true)}
                                content={"Avertir le membre"}/>
                    </Can>
                </div>
                {total > 0 ? (
                    <div className="warning-list">
                        {data.map(item =>  (
                            <ul key={item._id}>
                                <li>{moment(item.createdAt).format("DD-MM-YYYY")}</li>
                                <li>{item.tag && (item.tag.title || "Autre")}</li>
                                <li>
                                    <Button
                                        size={"small"}
                                        onClick={() => {
                                            setCurrentWarningId(item._id);
                                            setToggleDetails(true)
                                        }}
                                        content={"Consulter le message"}
                                    />
                                </li>
                            </ul>
                        ))}
                    </div>
                ) : (
                    <div className={"no-content"}>Aucun message de modération</div>
                )}

                <Drawer
                    open={toggleAlertDeleteWarn}
                    handleClose={()=>{setToggleAlertDeleteWarn(false); setCurrentWarningId(null)}}
                    headContent={"Attention"}
                >
                    <Alert text={"Voulez vous vraiment supprimer cet message de modération ?"}
                           handleDelete={handleDeleteWarn}
                           handleClose={()=>{setToggleAlertDeleteWarn(false); setCurrentWarningId(null)}}
                    />
                </Drawer>
                <Modal toggle={toggleCreateWarning} handleClose={() => {setToggleCreateWarning(false); setCurrentWarningId(null)} }>
                    <WarningCreate
                        username={username}
                        createUserWarning={(data) => mutateCreateWarning(data)}
                        handleClose={() => {setToggleCreateWarning(false); setCurrentWarningId(null)} }
                    />
                </Modal>
                <Modal toggle={toggleDetails} handleClose={() => setToggleDetails(false)}>
                    <WarningDetail
                        handleClose={() => setToggleDetails(false)}
                        warningId={currentWarningId}
                        userId={userId}
                        handleAccept={(warningId) => mutateAcceptedWarning(warningId)}
                        handleDeleteWarn={handleDeleteWarn}
                        updateStatus={(warningId, data) => mutateUpdatedStatusWarning({warningId, data})}
                        updateContent={(warningId, data) => mutateUpdatedContentWarning({warningId, data})}
                    />
                </Modal>
            </div>
        </div>
    )
};

export default Warning;
