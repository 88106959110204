import {USER_CURRENT_KEY} from "../api/public-voissa/users";
import {useQueryClient} from "@tanstack/react-query";
import createLink from "../libs/createLink";
import {useNavigate} from "react-router-dom";
import {ModalContext} from "../ui/components2/ModalManager";
import {useContext} from "react";


export const USER_LOCKED_LABEL = {
    ROLE_GUEST : 'ROLE_GUEST',
    ROLE_EMAIL_UNCONFIRMED : 'ROLE_EMAIL_UNCONFIRMED',
    ROLE_BANNED : 'ROLE_BANNED',
    ROLE_NO_PREMIUM : 'ROLE_NO_PREMIUM'
};


export const ACTION_LOCKED = {
    CREATE_LIKE : 'CREATE_LIKE',
    CREATE_BOOKMARK : 'CREATE_BOOKMARK',
    CREATE_FOLLOW : 'CREATE_FOLLOW',
    CREATE_COMMENT : 'CREATE_COMMENT',
    CREATE_FRIEND : 'CREATE_FRIEND',
    CREATE_ALBUM : 'CREATE_ALBUM',
    CREATE_ARTICLE : 'CREATE_ARTICLE',
    CREATE_TOPIC : 'CREATE_TOPIC',
    CREATE_TOPIC_COMMENT : 'CREATE_TOPIC_COMMENT',
    CREATE_STATUS : 'CREATE_STATUS',
    UPLOAD : 'UPLOAD',
    CHAT_WEBCAM : 'CHAT_WEBCAM',
    ROOMS : 'ROOMS',
    PREMIUM : 'PREMIUM'
};

const useCurrentUserActionLocked = ({
        action,
        contentOwner
    })=>{

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { openModal } = useContext(ModalContext);
    const currentUser = queryClient.getQueryData([USER_CURRENT_KEY]);
    const currentRole = currentUser?.role || "guest";

    let lockedReason = null;

    let isEmailUnconfirmed = null;
    let isBanned = null;
    let isGuest = null;
    let isNoPremium = null;

    if(currentUser?._id){
        isEmailUnconfirmed = currentRole === "emailUnconfirmed" ? USER_LOCKED_LABEL.ROLE_EMAIL_UNCONFIRMED : null;
        isBanned = currentRole === "banned" ? USER_LOCKED_LABEL.ROLE_BANNED : null;
        isNoPremium = currentUser?.customer?.subscribed ? null : USER_LOCKED_LABEL.ROLE_NO_PREMIUM;
    }
    else{
        isGuest = currentRole === "guest" ? USER_LOCKED_LABEL.ROLE_GUEST : null;
    }

    lockedReason = isGuest || isBanned || isEmailUnconfirmed || isNoPremium;

    const actionDefault = () =>{
        if([2,3,4].includes(currentUser?.profile?.gender)){
            navigate(createLink("userMeSettingsAbonnement", currentUser));
        }
        else if(currentUser?._id){
            openModal('ModalSubscription', {toggle : true, isModal : true, lockedContentOwner : contentOwner, lockedReason, lockedAction : action});
        }
        else{
            openModal('Signin', {lockedContentOwner : contentOwner, lockedReason, lockedAction : action});
        }
    }

    const actionStrategiesNoPremium = {
        [ACTION_LOCKED.ROOMS]: () => {
            actionDefault();
        },
        [ACTION_LOCKED.PREMIUM]: () => {
            actionDefault();
        },
        [ACTION_LOCKED.CREATE_FRIEND]: () => {
            actionDefault();
        },
        [ACTION_LOCKED.CHAT_WEBCAM]: () => {
            actionDefault();
        },
    };


    function handleLockedAction(){
        if(!lockedReason) return false;
        if (lockedReason === USER_LOCKED_LABEL.ROLE_NO_PREMIUM) {
            const actionFunction = actionStrategiesNoPremium[action];
            if (actionFunction) {
                actionFunction();
                return true;
            }
        }
        switch (lockedReason) {
            case USER_LOCKED_LABEL.ROLE_GUEST:
                //navigate(createLink("signin"));
                openModal('Signin', {lockedContentOwner : contentOwner, lockedReason, lockedAction : action});
                break;
            case USER_LOCKED_LABEL.ROLE_EMAIL_UNCONFIRMED:
                openModal('EmailUnconfirmed', {toggle : true, isModal : true});
                break;
            case USER_LOCKED_LABEL.ROLE_BANNED:
                navigate(createLink("signin"));
                break;
            case USER_LOCKED_LABEL.ROLE_NO_PREMIUM:
                actionDefault()
                break;
            default:
                return false;
        }
        return true;
    }

    return {locked:!!lockedReason, lockedReason, handleLockedAction};

}

export default useCurrentUserActionLocked;