import React, {useState} from 'react';
import classNames from "classnames";
import {SpinLoader} from "./Loader";
import _ from "lodash";
import createLink from "../../libs/createLink";
import { Link } from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {fetchOffers, fetchOfferFormData, OFFERS} from "../../api/public-voissa/offers";
import {fetchCurrentUserAbo} from "../../api/public-voissa/users";
import {useBreakpoint} from "../../libs/breakpoint";


let advantages = [
    "Chat illimité avec tous les membres",
    "Accès au chat webcam",
    //"Publier et consulter les annonces de rencontre",
    "Ajouter des membres dans mon cercle"
];

const maxRetry = 10;

const Subscription = ({
                          handleClose = () =>{},
                          className = "",
                          isArchived = false
                      }) =>{
    //const queryClient = useQueryClient();

    const [step, setStep] = useState(1);
    const [selectedOfferPrice, setSelectedOfferPrice] = useState("");
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [refetchInterval, setRefetchInterval] = useState(0);
    const [refetchIntervalCount, setRefetchIntervalCount] = useState(0);


    let popupInterval = null;

    const breakpoints = useBreakpoint();

    /*const dataCurrentUser = queryClient.getQueryData([USER_CURRENT_KEY]);

    if(_.get(dataCurrentUser, 'abTestings[0]')){
        isArchived = dataCurrentUser.abTestings.filter(item => item._id === "647858afde31e8e3e511321a") ? true : isArchived;
    }*/

    let stepsComponents = [
        _renderStepOne,
        _renderStepTwo,
        _renderStepThree,
        _renderStepFour,
        _renderStepFive
    ];

    const {isSuccess : isSuccessOffers ,data : dataOffers, isFetched : isFetchedOffers} = useQuery(
        [OFFERS],
        ()=> fetchOffers(),
        {
            staleTime : (1000 * 60 * 5),
            placeholderData : [],
            select : (data) =>data.data || []
        }
    );

    let offers = dataOffers || [];

    const {data : dataUserAbo, isError : isErrorUserAbo} = useQuery(
        ['USER_ABO'],
        ()=> fetchCurrentUserAbo(),
        {
            staleTime : 3000,
            refetchOnWindowFocus : true,
            refetchInterval : refetchInterval,
            onSuccess : async () =>{
                await fetchAbo();
            }
        }
    );

    const {
        mutate : mutateFormOffer,
        isLoading : isLoadingFormOffer
    } = useMutation(offerId => fetchOfferFormData(offerId),{
        onSuccess: (data) =>{
            if(data?.urlForm){
                if(data?.type === "form"){
                    _submitForm(data);
                }
                else{
                    let isMobile = breakpoints.md;
                    if(!isMobile){
                        let left = (window.screen.width / 2) - (600 / 2);
                        let top = (window.screen.height / 2) - (780 / 2);
                        let popup = window.open('', 'formVoissa', 'toolbar=0,location=0,directories=0,status=1,menubar=0,scrollbars=1,resizable=1,width=600,height=780,top=' + top + ',left=' + left);
                        popupInterval = setInterval(()=>{
                            if(popup.closed){
                                clearInterval(popupInterval);
                                //nextStep();
                                setStep(step + 1);
                                setRefetchInterval(3000);
                            }
                        }, 200)
                    }

                    let form = window.document.createElement("form");
                    form.setAttribute("method", "get");
                    form.setAttribute("action", data?.urlForm);
                    if(!isMobile) {
                        form.setAttribute("target", "formVoissa");
                    }

                    window.document.body.appendChild(form);

                    form.submit();

                }

            }
        }
    });


    async function fetchAbo(){
        let checkIsAbo = _.get(dataUserAbo, "abo") || false;
        if(checkIsAbo && step !== 1){
            setStep(5);
            setRefetchInterval(0);
            //await queryClient.invalidateQueries([USER_CURRENT_KEY]);
            window.location.reload();
        }
        else if(checkIsAbo && step === 1){
            setRefetchInterval(0);
            window.location.reload();
            //await queryClient.invalidateQueries([USER_CURRENT_KEY]);
        }
        if(refetchIntervalCount >= maxRetry){
            setRefetchInterval(0);
        }
        setRefetchIntervalCount(refetchIntervalCount + 1);
    }

    function _submitForm(currentOffer) {
        let isMobile = breakpoints.md;
        let popup = null;
        if(!isMobile) {
            let left = (window.screen.width / 2) - (600 / 2);
            let top = (window.screen.height / 2) - (780 / 2);
            popup = window.open('', 'formVoissa', 'toolbar=0,location=0,directories=0,status=1,menubar=0,scrollbars=1,resizable=1,width=600,height=780,top=' + top + ',left=' + left);
        }
        let isAbo = _.get(dataUserAbo, "abo") || false;
        if(isAbo){
            setRefetchInterval(0);
            setStep(1);
            if(popup) {
                popup.close();
            }
        }
        else{
            let form = window.document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", currentOffer.urlForm);
            if(!isMobile) {
                form.setAttribute("target", "formVoissa");
            }

            let data = currentOffer.data || {};
            Object.keys(data).forEach(key =>{
                let inputIds = window.document.createElement('input');
                inputIds.type = 'hidden';
                inputIds.name = key;
                inputIds.value = data[key];
                form.appendChild(inputIds);
            })


            window.document.body.appendChild(form);

            form.submit();
            if(!isMobile) {
                popupInterval = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(popupInterval);
                        //nextStep();
                        setStep(step + 1);
                        setRefetchInterval(3000);
                        if (form && window.document?.body?.removeChild) {
                            window.document.body.removeChild(form);
                        }
                    }
                }, 200)
            }
        }
    }



    function _handleSubmit() {
        mutateFormOffer(selectedOffer._id);
    }

    const customSort = (a, b) => {
        const order = ['mobiyo', 'neosurf', 'vendo'];
        const indexA = order.indexOf(a.processeur);
        const indexB = order.indexOf(b.processeur);

        if (indexA === -1 && indexB === -1) {
            return a.processeur.localeCompare(b.processeur);
        } else if (indexA === -1) {
            return 1;
        } else if (indexB === -1) {
            return -1;
        } else {
            return indexA - indexB;
        }
    };



    function _renderStepOne() {
        return isSuccessOffers && !offers.length ? (
            <div id="msub-step3">
                <div className="msub-title">
                    Votre accès <span>Premium</span>
                </div>
                {!isFetchedOffers ? (
                    <SpinLoader />
                ) : (
                    <p>Nous vous prions de nous excuser, mais aucune offre d'abonnement n'est disponible actuellement.</p>
                )}

                <div
                    className="msub-button-step activated mt-8"
                    onClick={()=>handleClose()}
                >
                    Fermer
                </div>
            </div>
        ) : (
            <div id="msub-step1">
                <div className="msub-title">
                    Votre accès <span>Premium</span>
                </div>
                <ul className="msub-advantages">
                    {isArchived ? <li>Accès aux vidéos de plus de 30 jours</li> : null}
                    {advantages.map((advantage, index) => (<li key={`advantage_${index}`}>{advantage}</li>))}
                </ul>

                <div
                    className={"msub-button-step activated"}
                    onClick={() => setStep(2)}
                >
                    Continuer
                </div>
                <div className="msub-footer-text">Paiement sécurisé - Anonymat garanti</div>
            </div>
        )
    }

    function _renderStepTwo() {
        //const uniquePrice = isSuccessOffers && offers.length ? [...new Set(offers.map(item => item.price_abo))] : [];
        const offerList = isSuccessOffers && offers.length ? offers : [];
        const groupedOffers = Object.values(offerList.reduce((acc, offer) => {
            const price = offer.price_abo;
            if (!acc[price]) {
                acc[price] = { price_abo: price, name: offer.name };
            }
            return acc;
        }, {}));

        return (
            <div id="msub-step1">
                <div className="msub-title">
                    Plusieurs offres de <span>paiement</span>
                </div>

                <div className="msub-offers-grid">
                    {groupedOffers.map(offer => (
                        <div key={offer.price_abo} className="msub-offer-col" onClick={() => setSelectedOfferPrice(offer.price_abo)}>
                            <div
                                className={classNames("msub-offer", {active: offer.price_abo === selectedOfferPrice})}>
                                <div className="msub-offer-price">
                                    <div className="msub-offer-title">
                                        {offer.name}
                                    </div>
                                    {offer.price_abo}<span>€</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div
                    className={classNames("msub-button-step", {activated: !!selectedOfferPrice})}
                    onClick={() => !!selectedOfferPrice && setStep(3)}
                >
                    {!!selectedOfferPrice ? "Continuer" : "Sélectionnez une offre"}
                </div>
                <div className="msub-footer-text">Paiement sécurisé - Anonymat garanti</div>
            </div>
        )
    }

    function _renderStepThree() {
        let offersList = offers.filter(item => item.price_abo === selectedOfferPrice);
        offersList = offersList.sort(customSort);
        if (!selectedOffer && offersList.length) {
            setSelectedOffer(offersList[0]);
        }

        return (
            <div id="msub-step3">
                <div className="msub-title">
                    Plusieurs méthodes de <span>paiement</span>
                </div>
                {_.get(selectedOffer, "processeur") === "neosurf" && selectedOffer ? (
                    <ul className="msub-advantages">
                        <li>Aucun renouvellement automatique</li>
                        <li>Paiement anonyme</li>
                    </ul>
                ) : null}
                {_.get(selectedOffer, "processeur") === "vendo" && selectedOffer ? (
                    <ul className="msub-advantages">
                        <li>Plusieurs modes de paiement disponible</li>
                        <li>Paiement sécurisé</li>
                    </ul>
                ) : null}
                {_.get(selectedOffer, "processeur") === "mobiyo" && selectedOffer ? (
                    <ul className="msub-advantages">
                        <li>Résiliez à tout moment</li>
                        <li>Support réactif</li>
                        <li>Anonymat garanti</li>
                        <li>Paiement sécurisé</li>
                    </ul>
                ) : null}
                {_.get(selectedOffer, "processeur") === "monerchy" && selectedOffer ? (
                    <ul className="msub-advantages">
                        <li>Une solution Open banking</li>
                        <li>Paiement sécurisé sans carte bancaire</li>
                    </ul>
                ) : null}
                <div className="msub-offers-grid">
                    {offersList.map(offer => (
                        <div key={offer?.processeur} className={offersList.length === 1 ? "msub-offer-full" : "msub-offer-col"} onClick={() =>  setSelectedOffer(offer)}>
                            {offer?.processeur === "mobiyo" ? (
                                <div
                                    className={classNames("msub-offer", {active: offer?.processeur === _.get(selectedOffer, "processeur")})}>
                                    <div className="msub-offer-subone">
                                        Abonnement par carte bancaire.
                                    </div>

                                </div>
                            ) : null}
                            {offer?.processeur === "neosurf" ? (
                                <div
                                    className={classNames("msub-offer", {active: offer?.processeur === _.get(selectedOffer, "processeur")})}>

                                    <div className="msub-offer-subone">
                                        Paiement anonyme avec un code.
                                    </div>

                                </div>
                            ) : null}
                            {offer?.processeur === "vendo" ? (
                                <div
                                    className={classNames("msub-offer", {active: offer?.processeur === _.get(selectedOffer, "processeur")})}>
                                    <div className="msub-offer-subone">
                                        Paiement SEPA ou crypto.
                                    </div>

                                </div>
                            ) : null}

                            {offer?.processeur === "monerchy" ? (
                                <div
                                    className={classNames("msub-offer", {active: offer?.processeur === _.get(selectedOffer, "processeur")})}>
                                    <div className="msub-offer-subone">
                                        Paiement sécurisé de banque à banque.
                                    </div>

                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>


                {_.get(selectedOffer, "processeur") === "neosurf" ? (
                    <div className="msub-pay-infos">
                        <p>Le paiement sera effectué en dehors de Voissa : soit en espèces dans un magasin, soit en
                            ligne avec la création d'un compte MyNeosurf.</p>
                        <p>Des revendeurs en ligne proposent d'acheter le code avec paiement d'une commission
                            supplémentaire.</p>
                        <p>Prestataire : VA MEDIA - 10 rue du Colisée 75008.</p>
                    </div>
                ) : null}


                {_.get(selectedOffer, "processeur") === "vendo" ? (
                    <div className="msub-pay-infos">
                        <p>Votre paiement fera apparaitre la mention <strong>VendoStore*VAMedia</strong> sur votre
                            relevé bancaire. Voissa ne sera pas mentionné.</p>
                        <p>Vous paierez {selectedOffer?.price_abo} euros pour {selectedOffer?.nb_jours_abo || 0} jours.
                            Reconduction de l’abonnement tous les {selectedOffer?.nb_jours_abo || 0} jours.</p>
                        <p>Prestataire : VA MEDIA - 10 rue du Colisée 75008.</p>
                    </div>
                ) : null}


                {_.get(selectedOffer, "processeur") === "mobiyo"  ? (
                    <div className="msub-pay-infos">
                        <p>Votre paiement fera apparaitre la mention VA MEDIA sur votre relevé bancaire.</p>
                        <p>Vous paierez {selectedOffer?.price_abo} euro
                            pour {selectedOffer?.nb_jours_abo || 0} jours.
                            Reconduction de l’abonnement tous
                            les {selectedOffer?.nb_jours_abo || 0} jours.</p>
                        <p>Prestataire : VA MEDIA - 10 rue du Colisée 75008.</p>
                    </div>
                ) : null}

                {_.get(selectedOffer, "processeur") === "monerchy" ? (
                    <div className="msub-pay-infos">
                        <p>Vous paierez {selectedOffer?.price_abo || 0} euros
                            pour {selectedOffer?.nb_jours_abo || 0} jours. Aucun renouvellement automatique.</p>
                        <p>Prestataire : VA MEDIA - 10 rue du Colisée 75008.</p>
                    </div>
                ) : null}

                <div
                    className={"msub-link-goback"}
                    onClick={() => {
                        setStep(2);
                        setSelectedOffer(null);
                    }}
                >
                    Retour
                </div>

                <div
                    className={classNames("msub-button-step", {activated: !!selectedOffer})}
                    onClick={() => !!selectedOffer && !isLoadingFormOffer && _handleSubmit()}
                >
                    {!!selectedOffer ? "Payer" : "Sélectionnez une offre"}
                    {isLoadingFormOffer ? <SpinLoader/> : null}
                </div>

            </div>
        );
    }

    function _renderStepFour() {
        return (
            <div id="msub-step3">
                <div className="msub-title">
                    <SpinLoader className={"small"}/>
                </div>
                <div className="msub-title">
                    Nous vérifions votre paiement...
                </div>
            </div>
        )
    }

    function _renderStepFive() {
        return (
            <div id="msub-step3">
                <div className="msub-title">
                    Votre accès <span>Premium</span>
                </div>
                <p>Nous avons le plaisir de vous confirmer votre accès Premium.</p>
                <div
                    className="msub-button-step activated mt-8"
                    onClick={() => handleClose()}
                >
                    Fermer
                </div>
            </div>
        )
    }

    function _isAbonned() {
        return (
            <div id="msub-step3">
                <div className="msub-title">
                    Votre accès <span>Premium</span>
                </div>
                <p>Vous êtes déjà abonné.</p>
                <div
                    className="msub-button-step activated mt-8"
                    onClick={() => handleClose()}
                >
                    Fermer
                </div>
            </div>
        )
    }

    function _isError() {
        return (
            <div id="msub-step3">
                <div className="msub-title">
                    Votre accès <span>Premium</span>
                </div>
                <p>Nous avons rencontré un problème durant le paiement, Merci de <Link
                    to={createLink("contact")}>contacter</Link> notre équipe</p>
                <div
                    className="msub-button-step activated mt-8"
                    onClick={() => handleClose()}
                >
                    Fermer
                </div>
            </div>
        )
    }


    return (
        <div id="ModalSubscription" className={className}>
            {_.get(dataUserAbo, 'abo') && step === 1 && !isErrorUserAbo
                ? (_isAbonned())
                : (isErrorUserAbo || refetchIntervalCount > maxRetry) ? (_isError()) : (stepsComponents[step - 1]())
            }
        </div>
    )
};


export default Subscription;