import React from 'react';

import Modal from "./Modal";
import Subscription from "./Subscription";


const ModalSubscription = ({
            toggle=false,
           handleClose = () =>{},
           className = "",
           isArchived = false
       }) =>{

        return <Modal toggle={toggle}
                      handleClose={() => handleClose()}>
            <Subscription  handleClose={() => handleClose()} className={className} isArchived={isArchived} />
        </Modal>

}

export default ModalSubscription;