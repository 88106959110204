import React from 'react';
import ReactDOM from 'react-dom';
import {useBreakpoint} from "../../../libs/breakpoint";
import './Drawer.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { DisableBodyScroll } from '../../../hooks/useDisableBodyScroll';

const Drawer = ({open=false, handleClose=()=>{}, children, headContent, closeBtn=true, xsmall, small, large, xlarge}) => {
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.xs || breakpoints.sm;
    return open ? ReactDOM.createPortal(
        <div className={classNames("drawer", {'modal-mode':!isMobile,xsmall, small, large, xlarge})}>
            <DisableBodyScroll/>
            <div className="drawer-wrapper" >
                <div className={classNames("drawer-head", {"has-head-contrent":!!headContent})}>
                    {headContent}
                    {closeBtn && (
                        <div className="drawer-close-btn" onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    )}
                </div>
                <div className="drawer-content">
                                {children}
                                
                </div>
            </div>
            <div className="drawer-close" onClick={handleClose}/>
        </div>
    ,
    document.body) : null;
};

export default Drawer;
