import React from 'react';
import './Group.scss';
import classNames from 'classnames';

const Group = ({split, grid, right, full, children,className, ...props}) => {
  return (
    <div className={classNames('group', className, {split, grid, right, full})} {...props}>
      {children}
    </div>
  );
};

export default Group;
